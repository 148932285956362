// sleep time expects milliseconds
function sleep (time) {
	return new Promise((resolve) => setTimeout(resolve, time));
}

jQuery(document).ready(function($) {

	$('.green_span_bg i').click(function () {
		$(this).parent().next().toggle();
		if ($(this).hasClass('fa-chevron-up')) {
			$(this).removeClass('fa-chevron-up').addClass('fa-chevron-down');
		} else {
			$(this).removeClass('fa-chevron-down').addClass('fa-chevron-up');
		}
	});

	/* ------------------- FLOATING MOBILE NAV FUNCTIONALITY --------------- */
	if ($( window ).width() < 576) {

		// sleep to adjust height accurately
		sleep(500).then(function() {
			var iconMenuHeight = $('.quick_menu').outerHeight(true);

			var navSection = $(".filter-section")
			navSection.css('top', iconMenuHeight)
            navSection.parent('.toplist-filter-container').css('min-height', navSection.outerHeight(true));

			$(document).scroll(function () {
                var selectorPosition = Math.round($('.toplist-filter').offset().top);
				var scroll = $(this).scrollTop();
                
				if (scroll >= (selectorPosition - iconMenuHeight)) {
					$(".scrolling-banner").removeClass("hidden");
					if ($(window).width() <= selectorPosition) {
						$(".scrolling-banner").css("display", "block");
						navSection.addClass('flighing-filter');
					} else {
						navSection.removeClass('flighing-filter');
					}

				} else {
					$(".scrolling-banner").addClass("hidden");

					if ($(window).width() <= 700) {
						$(".scrolling-banner").css("display", "");
						navSection.removeClass('flighing-filter');
						$(".banner").removeClass('bannerscroll');
					}
				}
			}).scroll();
		})
	}


	// mobile click to toplist button functionality
	$('#scroll-to-toplist').click(function() {
        var prevToFilterBar = $('.toplist-filter');
		var quickMenuHeight = $('.quick_menu').outerHeight(true);
		var scrollPos = Math.round(prevToFilterBar.offset().top) - quickMenuHeight + 1;
	   
		$("html, body").animate({ scrollTop: scrollPos }, 250);
	});


	// mobile tooltip functionality
	if ($(window).width() <=768) {
		$('.tooltip').click(function() { // click on tooltip
			var clicked = $(this)
				clicked.find('.tooltip-text').css('opacity', '1')
		})

		$(document).click(function(event) { // click outside tooltip
			if(!$(event.target).closest('.tooltip').length) {
				$(".tooltip-text").css('opacity', '0')
			}
		})
	}
	
});

